/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;800&display=swap'); */

@import './css/style.css';
@import './css/AllPolls.css';
@import './css/loader.css';
body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
    /* font-family: 'Poppins', sans-serif;
    background: var(--light-white);
    color: #444444; */
}